import { useField } from 'formik';
import {
  SoftSkillFragment,
  useGetSzAndProvidedCompanySoftSkillsQuery,
} from 'generated/graphql';
import { FC, useMemo } from 'react';
import {
  IFormikSelectFormField,
  SelectFormField,
  TNotification,
  TSelectOption,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

export const OPTIONS_SPLITTER = '-----------------------------';

const useGetOptions = (companyId: number): { options: TSelectOption[] } => {
  const { handleMsgType } = useNotification();

  const softSkillsQueryResponse = useGetSzAndProvidedCompanySoftSkillsQuery({
    variables: { companyId },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const parseSoftSkills = (softSkills?: SoftSkillFragment[] | null) => {
    return (
      softSkills
        ?.map(({ id, name }) => ({
          value: `${id}`,
          label: name,
        }))
        .sort((a, b) =>
          a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1
        ) || []
    );
  };

  const companyOwnSoftSkills = useMemo(
    () => parseSoftSkills(softSkillsQueryResponse.data?.CompanySoftSkills),
    [softSkillsQueryResponse.data?.CompanySoftSkills]
  );

  const szOwnSoftSkills = useMemo(
    () => parseSoftSkills(softSkillsQueryResponse.data?.SZSoftSkills),
    [softSkillsQueryResponse.data?.SZSoftSkills]
  );

  const options = [
    ...companyOwnSoftSkills,
    {
      label: OPTIONS_SPLITTER,
      value: OPTIONS_SPLITTER,
    },
    ...szOwnSoftSkills,
  ];

  return { options };
};

interface IFormikSoftSkillNameSelectFormField
  extends Omit<IFormikSelectFormField, 'options'> {
  companyId: string;
}

export const FormikSoftSkillNameSelectFormField: FC<IFormikSoftSkillNameSelectFormField> =
  ({ companyId, id, onChange, ...rest }) => {
    const { options } = useGetOptions(companyId ? Number(companyId) : 1);
    const [field, meta, helpers] = useField<TSelectOption>(id);

    const handleChange = (newValues: TSelectOption) => {
      if (newValues.value !== OPTIONS_SPLITTER) {
        helpers.setValue(newValues);
        onChange?.(newValues, id);
      }
    };

    const hasError = Boolean(meta.error && meta.touched);
    const error = meta.error as unknown as
      | { label: string; value: string }
      | undefined;

    const value = options.find(option => option.value === field.value.value);

    return (
      <SelectFormField
        {...rest}
        id={id}
        value={value}
        onChange={handleChange}
        options={options}
        hasError={hasError}
        bottomText={hasError ? error?.value : ''}
        hasClearIndicator={false}
      />
    );
  };
