import {
  ProjectCompanyOrderField,
  ProjectFindManyPaginatedQuery,
  SortOption,
  useProjectFindManyPaginatedQuery,
} from 'generated/graphql';
import { formatProjectDate } from 'helpers/project';
import { useEffect, useState } from 'react';
import {
  TNotification,
  TSelectOption,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

type UseProjectsSelectProps = number[];

export function useProjectsSelect(companyIds?: UseProjectsSelectProps) {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string | undefined>('');
  const [projectsForSelect, setProjectsForSelect] = useState<TSelectOption[]>(
    []
  );

  const { handleMsgType } = useNotification();

  const { loading, data } = useProjectFindManyPaginatedQuery({
    variables: {
      args: {
        projectOrCompanyNameContains: searchValue,
      },
      ...(companyIds && {
        filter: {
          companyIds,
        },
      }),
      order: {
        direction: SortOption.Asc,
        field: ProjectCompanyOrderField.ProjectName,
      },
      paginate: {
        page: currentPage,
        size: 100,
      },
    },
    onCompleted: data => {
      const parsedData = parseDataForSelect(data);
      setProjectsForSelect(_prev => [..._prev, ...parsedData]);
    },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: `${error.message}`,
      });
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setProjectsForSelect([]);
    setCurrentPage(1);
  }, [searchValue]);

  const itemsTotal = data?.ProjectFindManyPaginated?.pageInfo?.itemsTotal || 0;

  const hasLoadMore = itemsTotal > projectsForSelect.length;

  return {
    selectOptions: projectsForSelect,
    loading,
    hasMoreItems: hasLoadMore,
    loadMore: () => setCurrentPage(_prev => _prev + 1),
    searchValue,
    setSearchValue,
  };
}

function parseDataForSelect(
  data: ProjectFindManyPaginatedQuery | undefined
): TSelectOption[] {
  if (!data || !data.ProjectFindManyPaginated.data) {
    return [];
  }

  return data.ProjectFindManyPaginated.data
    ?.filter(project => !project.isArchived)
    .map(project => ({
      value: String(project.id),
      label: `${project.name} (${project.id})`,
      labelNote: `Last edited ${formatProjectDate(project.updatedAt)}`,
      labelDescription: `${project.isStopped ? 'Closed' : 'Open'}`,
    }));
}
