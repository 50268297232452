import { DEBOUNCE_TIME_DEFAULT } from 'constants/debounce';
import { Form, Formik, useField } from 'formik';
import { uploadService } from 'helpers/uploadService';
import { useCompanyAtsSelect } from 'hooks/ats/useCompanyAtsSelect/useCompanyAtsSelect';
import { useDepartmentsSelect } from 'hooks/ats/useDepartmentsSelect';
import { useLocationsSelect } from 'hooks/ats/useLocationSelect';
import { ReactComponent as QuestionMarkIcon } from 'icons/questionmark.svg';
import { ReactComponent as ClockIcon } from 'icons/schedule.svg';
import { debounce } from 'lodash';
import { useState } from 'react';
import * as yup from 'yup';
import {
  Button,
  FormikMultiselectFormField,
  FormikSelectFormField,
  InformationalContent,
  TNotification,
  TSelectOption,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import styles from './AtsRequisitions.module.scss';
import { FormData } from './AtsRequisitions.types';

export function AtsRequisitions() {
  const { handleMsgType } = useNotification();
  const [selectedCompanyId, setSelectedCompanyId] = useState<
    number | undefined
  >(undefined);
  const [isDownloading, setIsDownloading] = useState(false);
  const [resetKey, setResetKey] = useState(crypto.randomUUID());

  const {
    selectOptions: companyOptions,
    loading: isLoadingCompanyOptions,
    searchValue: companySearchValue,
    setSearchValue: setCompanySearchValue,
    hasMoreItems: hasMoreCompanyItems,
    loadMore: loadMoreCompanies,
  } = useCompanyAtsSelect();

  const {
    selectOptions: departmentOptions,
    loading: isLoadingDepartmentOptions,
    searchValue: departmentSearchValue,
    setSearchValue: setDepartmentSearchValue,
    hasMoreItems: hasMoreDepartmentItems,
    loadMore: loadMoreDepartments,
  } = useDepartmentsSelect(selectedCompanyId);

  const {
    selectOptions: locationOptions,
    loading: isLoadingLocationOptions,
    searchValue: locationSearchValue,
    setSearchValue: setLocationSearchValue,
    hasMoreItems: hasMoreLocationItems,
    loadMore: loadMoreLocations,
  } = useLocationsSelect(selectedCompanyId);

  async function handleSubmit(data?: FormData) {
    if (!data?.company?.value) {
      return;
    }

    const departments = !data?.departments
      ? undefined
      : data.departments.map(dp => dp.value);

    const locations = !data?.locations
      ? undefined
      : data.locations.map(lc => lc.value);

    setIsDownloading(true);

    const { error } = await uploadService.download(
      'stack-one/exports/jobs',
      'report.csv',
      {
        companyId: Number(data.company.value),
        departmentIds: departments,
        locationIds: locations,
      }
    );

    setIsDownloading(false);

    if (!error) {
      handleMsgType({
        type: TNotification.success,
        title: 'Download started successfully',
      });
      return;
    }

    if (!error.error && !error.message) {
      handleMsgType({
        type: TNotification.error,
        title: 'Unable to start file download.',
        message: 'Something went wrong. Please try again.',
      });
      return;
    }

    if (error.error === 'Unauthorized') {
      handleMsgType({
        type: TNotification.error,
        title: 'No file available to download',
        message: `The company's ATS is not integrated with Spotted Zebra. If this is incorrect, please contact engineering.`,
      });
      return;
    }

    handleMsgType({
      type: TNotification.error,
      title: error.error,
      message: error.message,
    });
  }

  function resetRender() {
    setSelectedCompanyId(undefined);
    setResetKey(crypto.randomUUID());
  }

  function handleSelectCompany(selectedCompany: TSelectOption) {
    if (!selectedCompany) {
      setSelectedCompanyId(undefined);
      resetRender();
      return;
    }
    setSelectedCompanyId(Number(selectedCompany.value));
  }

  function handleInputChangeCompany(value: string) {
    if (!value && companySearchValue !== '') {
      setCompanySearchValue('');
      return;
    }

    if (value) {
      setCompanySearchValue(value);
      return;
    }
  }

  function handleInputChangeDepartments(value: string) {
    if (!value && departmentSearchValue !== '') {
      setDepartmentSearchValue('');
      return;
    }

    if (value) {
      setDepartmentSearchValue(value);
      return;
    }
  }

  function handleInputChangeLocations(value: string) {
    if (!value && locationSearchValue !== '') {
      setLocationSearchValue('');
      return;
    }

    if (value) {
      setLocationSearchValue(value);
      return;
    }
  }

  const debouncedInputChangeCompany = debounce(
    handleInputChangeCompany,
    DEBOUNCE_TIME_DEFAULT
  );

  const debouncedInputChangeDepartments = debounce(
    handleInputChangeDepartments,
    DEBOUNCE_TIME_DEFAULT
  );

  const debouncedInputChangeLocations = debounce(
    handleInputChangeLocations,
    DEBOUNCE_TIME_DEFAULT
  );

  return (
    <main className={styles.container}>
      <h1 className={styles.title}>ATS requisitions data</h1>

      <section className={styles.body} key={resetKey}>
        <div className={styles.form}>
          <Formik<FormData>
            initialValues={initialFormValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ values, resetForm }) => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                }}
              >
                <FormikSelectFormField
                  options={companyOptions}
                  id="company"
                  name="company"
                  label="Company"
                  placeholder="Company"
                  key="ats-integration__form-select--company"
                  useFormikField={useField}
                  onLoadMore={loadMoreCompanies}
                  onChange={handleSelectCompany}
                  onInputChange={debouncedInputChangeCompany}
                  loadMore={hasMoreCompanyItems}
                  loadMoreButtonText="Load more"
                  isLoading={isLoadingCompanyOptions}
                  onClearBtnClick={resetRender}
                />

                <FormikMultiselectFormField
                  options={departmentOptions}
                  id="departments"
                  name="departments"
                  label="Department"
                  placeholder="Department"
                  key="ats-integration__form-select--department"
                  useFormikField={useField}
                  onLoadMore={loadMoreDepartments}
                  onInputChange={debouncedInputChangeDepartments}
                  loadMore={hasMoreDepartmentItems}
                  loadMoreButtonText="Load more"
                  isLoading={isLoadingDepartmentOptions}
                  isDisabled={!values.company?.value}
                />

                <FormikMultiselectFormField
                  options={locationOptions}
                  id="locations"
                  name="locations"
                  label="Location"
                  placeholder="Location"
                  key="ats-integration__form-select--locations"
                  useFormikField={useField}
                  onLoadMore={loadMoreLocations}
                  onInputChange={debouncedInputChangeLocations}
                  loadMore={hasMoreLocationItems}
                  loadMoreButtonText="Load more"
                  isLoading={isLoadingLocationOptions}
                  isDisabled={!values.company?.value}
                />

                <div className={styles.btnWrapper}>
                  <Button
                    type="button"
                    loading={isDownloading}
                    disabled={isDownloading || !values.company?.value}
                    className={styles.submitBtn}
                    onClick={() => handleSubmit(values)}
                  >
                    Download export
                  </Button>

                  <Button
                    onClick={() => {
                      resetForm();
                      resetRender();
                    }}
                    variant="tertiary"
                    disabled={!values.company?.value}
                  >
                    Reset
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className={styles.info}>
          <InformationalContent
            orientation="vertical"
            items={[
              {
                title: 'How it works',
                content: `Download a CSV file of requisition data extracted through our integrations with clients' ATSs. The data is synced weekly and represents a snapshot of the most up-to-date information at the time of the sync.`,
                icon: <QuestionMarkIcon />,
              },
              {
                title: 'Timeframe',
                content: `Requisition data is extracted from the last 12 months. A job is considered relevant if it has been created, updated, or closed within that timeframe.`,
                icon: <ClockIcon />,
              },
            ]}
          ></InformationalContent>
        </div>
      </section>
    </main>
  );
}

const initialFormValues = {
  company: undefined,
  departments: undefined,
  locations: undefined,
};

const validationSchema = yup.object().shape({
  company: yup.object().shape({
    value: yup.number().required('Required field'),
    label: yup.string().required('Required field'),
  }),
});

export default AtsRequisitions;
